import React from "react";
import classes from './style.module.css'
import { useState } from "react";

const Input = React.forwardRef((props, ref) => {

    const [error, setError] = useState('')
    const cssInput = [classes.Input]

    if(props.error) {
        cssInput.push(classes.error)
        setError(props.errorText)
    }

    return(
        <div className={cssInput.join(' ')}>
            <label htmlFor={props.name}>{props.legend}</label>
            <input ref={ref} type={props.type} name={props.name} />
            <span className={classes.ErrorText}>{error}</span>
        </div>
    )
})

export default Input