import React from "react";
import classes from './style.module.css'

function Warning(props) {
    const cssWarning = [classes.Warning]
    if(props.active) {
        cssWarning.push(classes.active)
    }
    return(
        <div className={cssWarning.join(' ')} onClick={e => [e.preventDefault(), props.setActive(false), props.censored(false)]}>
            <div>
                {props.warning}
            </div>
        </div>
    )
}

export default Warning