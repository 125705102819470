import React from "react";
import classes from './style.module.css'

function Preloader(props) {
    const cssPreloader = [classes.Preloader]
    if(props.active) {
        cssPreloader.push(classes.active)
    }
    return(
        <div className={cssPreloader.join(' ')}></div>
    )
}

export default Preloader