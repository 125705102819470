import React from "react";
import { useState } from "react";
import Button from './components/Button'
import Form from "./components/Form";
import PostStoreForm from "./components/PostStoreForm";
import classes from './components/style.module.css'
import PostsBlock from "./components/PostsBlock";
import Preloader from "./components/Preloader";
import RegForm from "./components/RegForm";
import Warning from "./components/Warning";

function App() {

    const [activeBtn, setBtn] = useState(true)
    const [actOutBtn, setActOutBtn] = useState(false)
    const [activeForm, setForm] = useState(false)
    const [PSForm, setPSForm] = useState(false)
    const [actRegForm, setRForm] = useState(false)
    const [storeBtn, setStoreBtn] = useState(false)
    const [postsBtn, setPostsBtn] = useState(false)
    const [token, setToken] = useState('')
    const [name, setName] = useState('')
    const [id, setId] = useState('')
    const [activePosts, setPosts] = useState(false)
    const [actPreloader, setPreloader] = useState(false)
    const [page, setPage] = useState(0)
    const [pageBtn, setPageBtn] = useState(false)
    const [warning, setWarning] = useState(false)
    const [warningTxt, setWarningTxt] = useState('Some warning')
    const [censored, setCensored] = useState(false)

    const [postList, setPostList] = useState([])
    const [authorList, setAuthorList] = useState([])

    const cssDefault = {
        'app': classes.App,
        'container': [classes.Container]
    }
    if(censored) {
        cssDefault['container'].push(classes.censored)
    }

    const funcs = {
        'open_form': () => {
            setForm(true)
            setPreloader(false)
        },
        'open_reg_form': () => {
            setRForm(true)
            setPreloader(false)
        },
        'logout': () => {
            const baseURL = 'https://rest-api.sharemeonline.ru/'
            const options_logout = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }
            fetch(baseURL + 'logout', options_logout)
            .then(response => {
                response.json()
                if(response.status == 200) {
                    setToken('')
                    setActOutBtn(false)
                    setBtn(true)
                    setStoreBtn(false)
                    setPSForm(false)
                    setPosts(false)
                    setName('')
                    setId('')
                    setPostsBtn(false)
                    setPreloader(false)
                }
            })
        },
        'store': () => {
            setPSForm(true)
            setActOutBtn(false)
            setStoreBtn(false)
            setPosts(false)
            setPreloader(false)
        },
        'store_ex': () => {
            setPSForm(false)
            setActOutBtn(true)
            setStoreBtn(true)
        },
        'login_set_for_user': () => {
            setStoreBtn(true)
            setPostsBtn(true)
            setPreloader(false)
        },
        'open_posts': () => {
            const baseURL = 'https://rest-api.sharemeonline.ru/'
            const options_posts = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({
                    'page': page
                })
            }
            fetch(baseURL + 'posts', options_posts)
            .then(response => {
                response.json()
                .then(body => {
                    if(response.status == 200) {
                        setAuthorList(body['authors'])
                        setPostList(body['posts'])
                        setPosts(true)
                        setPageBtn(true)
                        setPreloader(false)
                    }
                })
            })
        },
        'load_more_posts': () => {
            setPage(page+1)
            const baseURL = 'https://rest-api.sharemeonline.ru/'
            const options_more_posts = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({
                    'page': page+1
                })
            }
            fetch(baseURL + 'posts', options_more_posts)
            .then(response => {
                response.json()
                .then(body => {
                    if(response.status == 200) {
                        setAuthorList([...authorList, ...body['authors']])
                        setPostList([...postList, ...body['posts']])
                        setPreloader(false)
                    }
                    else if (response.status == 201) {
                        setWarningTxt('Постов не найдено')
                        setWarning(true)
                        setPreloader(false)
                        setCensored(true)
                    }
                })
            })
        }
    }


    return (
        <div className={cssDefault['app']}>
            <div className={cssDefault['container'].join(' ')}>
                <h1>{id} {name}</h1>
                <Button active={actOutBtn} loading={setPreloader} setActive={setActOutBtn} legend='Выйти из аккаунта' func={funcs['logout']} />
                <Button active={activeBtn} loading={setPreloader} setActive={setBtn} legend='Войти в аккаунт' func={funcs['open_form']} />
                <Button active={activeBtn} loading={setPreloader} setActive={setBtn} legend='Зарегистрироваться' func={funcs['open_reg_form']} />
                <Form
                    loginBtn={activeBtn}
                    setLoginBtn={setBtn}
                    logoutBtn={actOutBtn}
                    setLogoutBtn={setActOutBtn}
                    id={id}
                    setId={setId}
                    username={name}
                    setUN={setName}
                    active={activeForm}
                    setActive={setForm}
                    token={token}
                    setToken={setToken}
                    storeBtn={storeBtn}
                    funcs={funcs['login_set_for_user']}
                    loading={setPreloader}
                />
                <RegForm
                    loginBtn={activeBtn}
                    setLoginBtn={setBtn}
                    logoutBtn={actOutBtn}
                    setLogoutBtn={setActOutBtn}
                    id={id}
                    setId={setId}
                    username={name}
                    setUN={setName}
                    active={actRegForm}
                    setActive={setRForm}
                    token={token}
                    setToken={setToken}
                    storeBtn={storeBtn}
                    funcs={funcs['login_set_for_user']}
                    loading={setPreloader}
                />
                <Button active={postsBtn} loading={setPreloader} setActive={setStoreBtn} legend='Смотреть посты' func={funcs['open_posts']} />
                <Button active={storeBtn} loading={setPreloader} setActive={setStoreBtn} legend='Запостить что-нибудь' func={funcs['store']}/>
                <PostStoreForm funcs={funcs['store_ex']} loading={setPreloader} active={PSForm} setActive={setPSForm} token={token} setToken={setToken} />
                {postList.map((post, num) =>
                    <PostsBlock
                        active={activePosts}
                        setActive={setPosts}
                        author={authorList[num]['name']}
                        post={post['post']}
                        key={num}
                    />
                )}
                <Button active={pageBtn} loading={setPreloader} setActive={setPageBtn} legend='Больше постов' func={funcs['load_more_posts']} />
                <Preloader active={actPreloader} setActive={setPreloader} />
            </div>
            <Warning active={warning} setActive={setWarning} censored={setCensored} warning={warningTxt} />
        </div>
    );
}

export default App;
