import React from "react";
import { useRef, useState } from "react";
import classes from './style.module.css'
import Input from './Input'
import Button from "./Button";

function PostStoreForm(props) {
    const cssForm = [classes.Form]

    const postRef = useRef()
    const [error, setError] = useState(false)

    if(props.active) {
        cssForm.push(classes.active)
    }

    function post()  {
        const baseURL = 'https://rest-api.sharemeonline.ru/'
        const options_post = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + props.token
            },
            body: JSON.stringify({
                'post': postRef.current.value
            })
        }
        props.loading(true)
        fetch(baseURL + 'store', options_post)
        .then(response => {
            response.json()
            console.log(response.status)
            props.funcs()
            props.loading(false)
        })
    }

    const funcs = {
        'close_form': () => {
            props.setActive(false)
        }
    }

    return(
        <div className={cssForm.join(' ')}>
            <form>
                <Button active={true} setActive={true} legend='Закрыть форму' func={funcs['close_form']}/>
                <Input ref={postRef} error={error} setError={setError} errorText='Неверное значение' type='text' name='post' legend='' />
                <button onClick={e => [e.preventDefault(), post()]}>Запостить</button>
            </form>
        </div>
    )
}

export default PostStoreForm