import React, { useRef } from "react";
import classes from './style.module.css'
import Button from "./Button";
import Input from "./Input";
import { useState } from "react";

function PostsBlock(props) {
    const cssPost = [classes.Post]

    if(props.active) {
        cssPost.push(classes.active)
    }

    return(
        <div className={cssPost.join(' ')}>
            <h1>{props.author}</h1>
            <p>{props.post}</p>
        </div>
    )
}

export default PostsBlock