import React, { useRef } from "react";
import classes from './style.module.css'
import Button from "./Button";
import Input from "./Input";
import { useState } from "react";

function Form(props) {

    const cssForm = [classes.Form]
    const [activeBtn, setBtn] = useState(true)
    const emailRef = useRef()
    const passRef = useRef()
    const [errorText, setErrorTxt] = useState('')
    const [error, setError] = useState(false)

    if(props.active) {
        cssForm.push(classes.active)
    }

    const funcs = {
        'close_form': () => {
            props.setActive(false)
        }
    }

    function callLogin() {
        const baseURL = 'https://rest-api.sharemeonline.ru/'
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                'email': emailRef.current.value,
                'password': passRef.current.value
            })
        }
        props.loading(true)
        fetch(baseURL + 'authorization', options)
        .then(response => {
            response.json()
            .then(body => {
                if(response.status == 422) {
                    setErrorTxt(body['message']['email'])
                }
                else if(response.status == 403) {
                    setErrorTxt(body['message'])
                }
                else if(response.status == 401) {
                    setErrorTxt('Try again')
                }
                else {
                    props.setToken(body['token'])
                    getName(body['token'])
                }
            })
        })
    }

    function getName(token) {
        const baseURL = 'https://rest-api.sharemeonline.ru/'
        const options_info = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        fetch(baseURL + 'info', options_info)
        .then(response => {
            response.json()
            .then(body => {
                props.setUN(body['name'])
                props.setId(body['id'])
                props.setLogoutBtn(true)
                props.setLoginBtn(false)
                props.funcs()
                props.setActive(false)
            })
        })
    }

    return(
        <div className={cssForm.join(' ')}>
            <form>
                {errorText}
                <Button active={activeBtn} setActive={setBtn} legend='Закрыть форму' func={funcs['close_form']}/>
                <Input ref={emailRef} error={error} setError={setError} errorText='Неверное значение' type='text' name='name' legend='Email' />
                <Input ref={passRef} error={error} setError={setError} errorText='Неверное значение' type='text' name='name' legend='Пароль' />
                <button className={classes.DefBtn} onClick={e => [e.preventDefault(), callLogin()]}>Войти</button>
            </form>
        </div>
    )
}

export default Form