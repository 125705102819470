import React from "react";
import classes from './style.module.css'

function Button(props) {

    const cssBtn = [classes.Btn]
    if(props.active) {
        cssBtn.push(classes.active)
    }
    return(
        <div className={cssBtn.join(' ')}>
            <button onClick={e => [e.preventDefault(), props.loading(true), props.func()]}>{props.legend}</button>
        </div>
    )
}

export default Button